<template>
<div>
    <div v-if="survey">
        <SurveyContent ref="surveyConent" :survey="survey" :surveyDataId="surveyDataId" @onSaved="saveSurvey"
                       :patient-id="patientId" :cachePrefix="ownerUserId" :ownerUserId="ownerUserId">
        </SurveyContent>
    </div>
    <SurveyPreviewDialog v-if="survey" :show.sync="showPreview" :survey="survey" @confirm="previewConfirm"></SurveyPreviewDialog>
</div>
</template>

<script>
import SurveyContent from "@/components/survey/components/SurveyContent";
import SurveyPreviewDialog from "@/components/survey/components/SurveyPreviewDialog";
export default {
    name: "Survey",
    components: {SurveyPreviewDialog, SurveyContent},
    data() {
        return {
            surveyId: undefined,
            survey: undefined,
            surveyDataId: undefined,
            ownerUserId: undefined,
            showPreview: false,
            surveyDataBeforeConfirm: undefined, //预览之前存放数据
            patientId: undefined
        }
    },
    created() {
        const surveyId = this.$route.query.survey
        if (!surveyId) {
            this.$message.error('参数错误')
            return
        }
        this.surveyId = surveyId
        this.surveyDataId = this.$route.query['survey-data']
        this.ownerUserId = this.$route.query.ownerUserId
        this.patientId = this.$route.query.patientId
        this.fetchSurvey()
    },
    methods: {
        fetchSurvey() {
            this.$httpUtil.post(this.$urlConstant.survey.surveyGetPost, this.$route.query,res => {
                this.survey = res.data
            },this)
        },
        saveSurvey(data) {
            const surveyData = data.surveyData
            // const survey = data.survey
            if (this.survey.previewBeforeSave) {
                this.surveyDataBeforeConfirm = surveyData
                this.showPreview = true
            }else {
                this.doSaveSurvey(surveyData)
            }
        },
        previewConfirm() {
            this.doSaveSurvey(this.surveyDataBeforeConfirm)
        },
        doSaveSurvey(surveyData) {
            if (this.ownerUserId) {
                surveyData.ownerUserId = this.ownerUserId
            }
            if (this.surveyDataId) {
                this.$httpUtil.put(this.$urlConstant.survey.surveyDataGetPut + this.surveyDataId, surveyData,() => {
                    this.doSuccess()
                },this)
            } else {
                this.$httpUtil.post(this.$urlConstant.survey.surveyDataManualPost, surveyData,() => {
                    this.$refs.surveyConent.cleanCachedData()
                    this.doSuccess()
                },this)
            }
        },
        doSuccess() {
            this.$router.replace({name: 'PatientList'})
        },
    }
}
</script>

<style scoped>

</style>